import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  totalCount: 0,
  isLoading: false
};

export const getLogsList = createAsyncThunk(
  'users/getLogsList',
  async (params) => {

    return {
      data: [{
        user_id: 1,
        fio: 'Иванов Иван Иванович',
        role: 'Администратор',
        email: 'konstantinopolkiy@mail.ru',
        event_date: '12.05.22- 22.05.22',
        event_date_time: '00:00',
        error: 'Описание ошибки',
        serviceName: 'Имя сервиса',
        message: 'Сообщение',
        data: 'Даные'
      }],
      total: 1
    };
  }
);

const logsSlice = createSlice({
  name: 'logsSlice',
  initialState,
  extraReducers: {
    [getLogsList.pending]: (state) => {
      state.isLoading = true;
    },
    [getLogsList.fulfilled]: (state, action) => {
      state.list = action.payload.data;
      state.totalCount = action.payload.total;
    },

  }
});

export const showLogsList = (state) => state.logs.list;
export const showLogsCount = (state) => state.logs.totalCount;

export default logsSlice.reducer;
