import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { auth, showAuth } from "../store/slice/authSlice";
import { NotFound } from "../components/notFound";
import { Logs } from "./logs";
import UserList from "./userList";

import {
  Header,
  goToAuth,
  getUnioneToken,
} from "@unione-pro/unione.commons.webpack";

function App() {
  const dispatch = useDispatch();
  const { isAuth, isLoading } = useSelector(showAuth);

  useEffect(() => {
    getUnioneToken()
      .then((token) => {
        if (token === null || token === undefined || token === 'null') {
          goToAuth();
        } else {
          dispatch(auth(token));
        }
      })
      .catch((e) => {
        console.log(process.env.REACT_APP_AUTH_HOST);
        console.log('token error : ', e);
      });
  }, [dispatch]);

  const MainContent = useCallback(() => {
    if (isLoading) {
      return (
        <div className="flex h-screen justify-center">
          <div className="m-auto">
            <h3 className="text-[#0071CE] font-semibold text-[20px] leading-6 text-center">
              Выполняется проверка
              <br />
              авторизации
            </h3>
          </div>
        </div>
      );
    }
    if (isAuth) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<UserList />} />
            <Route path="/logs" exact element={<Logs />} />
            <Route path="*" exact element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      );
    }
    return (
      <div className="flex h-screen justify-center">
        <div className="m-auto">
          <h3 className="text-[#3B4168] font-mont_serif font-semibold not-italic text-h1_main">
            Доступ запрещен
          </h3>
        </div>
      </div>
    );
  }, [isAuth, isLoading]);

  return (
    <>
      <Header />
      <MainContent />
      <Toaster position="top-right" toastOptions={{ duration: 4000 }} />
    </>
  );
}

export default App;
