import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../hooks/useDebounce';

import UsersTable from '../../components/usersTable';
import UserFilter from '../../components/userFilter';
import ModalRole from '../../components/modals/modalRole';
import ModalAddUser from '../../components/modals/modalAddUser';
import Pagination from '../../components/pagination';
import {
  showUserListMain,
  showTotalCount,
  showQueryParams,
  getUsersList,
  changeRole,
  deleteUser,
  setQueryParams,
} from '../../store/slice/usersSlice';

function UserList() {
  const [showModalRole, setModalRole] = useState(false);
  const [showModalAddUser, setModalAddUser] = useState(false);
  const [userId, setUserId] = useState(0);

  const dispatch = useDispatch();
  const userList = useSelector(showUserListMain);
  const totalCount = useSelector(showTotalCount);
  const queryParams = useSelector(showQueryParams);

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch, queryParams]);

  const debounceSearch = useDebounce((event) => {
    dispatch(setQueryParams({ ...queryParams, page: 1, search: event.target.value}));
  });

  const handleChangePagination = (paginationParams) => {
    dispatch(setQueryParams({ ...queryParams, ...paginationParams }));
  };

  const handlerChangeRole = (userId, role) => {
    dispatch(changeRole({ userId, role }))
      .unwrap()
      .then(() => {
        dispatch(getUsersList());
      });
  };

  const handlerDeleteUser = (id) => {
    dispatch(deleteUser({ id }));
  };

  const handlerShowModalRole = (id) => {
    setModalRole(!showModalRole);
    setUserId(id);
  };

  const handlerShowModalAddUser = () => {
    setModalAddUser(!showModalAddUser);
  };

  const handlerHideModal = () => {
    setModalRole(false);
  };

  return (
    <div className="flex flex-row">

      <div className="bg-bg_gray min-h-screen pt-[50px] px-12">
        <h1 className="text-[#3B4168] font-mont_serif font-semibold not-italic text-h1_main">
          Список пользователей
        </h1>

        <UserFilter
          handlerSearch={debounceSearch}
        />
        <UsersTable
          userList={userList}
          handlerShowModalRole={handlerShowModalRole}
          handlerShowModalAddUser={handlerShowModalAddUser}
          handlerDeleteUser={handlerDeleteUser}
        />
        <Pagination
          {...queryParams}
          totalCount={totalCount}
          paginationChange={handleChangePagination}
        />
      </div>
      {showModalRole && (
        <ModalRole
          userData={userList[userId]}
          close={handlerHideModal}
          handlerChangeRole={handlerChangeRole}
        />
      )}
      {showModalAddUser && (
        <ModalAddUser
          close={handlerShowModalAddUser}
        />
      )}
    </div>
  );
}

export default UserList;
