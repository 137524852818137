import { Popover } from "../usersTable/Popover";

function ModalDeleteUser({ userData, close, handlerDeleteUser }) {

  return (
    <>
      <div
        className="fixed z-[1000] inset-0 bg-opacity-50 bg-black cursor-pointer"
        onClick={close}
      ></div>
      <div className="z-[10000] max-w-[432px] fixed  left-2/4 top-2/4 transform translate-y-[-50%] translate-x-[-50%] bg-white rounded-[8px] border-t-[4px] border-[red] p-[40px]">
        <div className="flex flex-row justify-between">
          <div className="bg-delete_icon w-[28px] h-[28px]"></div>
          <div
            className="bg-close w-[28px] h-[28px] cursor-pointer"
            onClick={close}
          ></div>
        </div>
        <h1 className="text-h1 text-ellipsis overflow-x-clip">
          Вы уверены, что хотите удалить пользователя{" "}
          <Popover text={userData.oneid.email}>
            <span>"{userData.oneid.email}"?</span>
          </Popover>
        </h1>
        <button
          className="bg-regal_blue text-white p-[8px] mt-[40px] rounded-[4px] w-full"
          onClick={() => {
            handlerDeleteUser(userData.id_auth);
            close();
          }}
        >
          Удалить пользователя
        </button>
      </div>
    </>
  );
}

export default ModalDeleteUser;
