import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../hooks/useDebounce";
import {
  addUser,
  getOneIdUsersList,
  showUserListEmail,
} from "../../store/slice/usersSlice";
import { useEffect, useState } from "react";
import { roles } from "../../constants/constants";

const INITIAL_PARAMS = { page: 1, limit: 10 };

function ModalAddUser({ close }) {
  const dispatch = useDispatch();
  const { list, totalCount, isLoading } = useSelector(showUserListEmail);
  const [params, setParams] = useState(INITIAL_PARAMS);
  const isMaxPage = params.page * params.limit >= totalCount;
  const [roleState, setRoleState] = useState(roles[3].value);
  const [showOptions, setShowOptions] = useState(false);

  const handlerSelect = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    dispatch(getOneIdUsersList(params));
  }, [params, dispatch]);

  const debounceSearch = useDebounce((event) => {
    setParams((prev) => ({ ...prev, page: 1, email: event.target.value }));
  });

  const handleAddUser = (idUser) => {
    dispatch(addUser({ idUser, role: roleState, params }));
  };

  const nextPage = () => {
    setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  return (
    <>
      <div
        className="fixed inset-0 z-[10] bg-opacity-50 bg-black cursor-pointer h-screen"
        onClick={close}
      />
      <div className="max-w-[432px] min-w-[432px] z-[11] max-h-[500px] overflow-y-auto scrollbar fixed left-2/4 top-2/4 transform translate-y-[-50%] translate-x-[-50%] bg-white rounded-[8px] border-t-[4px] border-[#0071CE] p-[40px]">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-[#1E253B] font-mont_serif font-semibold not-italic text-h3s_main">
            Добавить пользователя
          </h2>
          <div
            className="bg-close w-[28px] h-[28px] cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="flex flex-col my-[23px]">
          <label className="not-italic font-normal text-text_light_gray text-tr">
            E-mail *
          </label>
          <input
            className="border-1px border-gray rounded-[4px] px-[20px] py-[8px] mb-[4px] mt-[4px] focus:border-blue focus:outline-none"
            name="email"
            placeholder="Введите почту"
            onChange={debounceSearch}
          />
        </div>

        <span className="text-text_light_gray not-italic font-normal text-tr  mb-[4px]">
          Роль пользователя
        </span>
        <div
          onClick={handlerSelect}
          className="cursor-pointer relative w-full z-10 mb-[23px]"
        >
          <div className="w-[12px] h-[12px] bg-arrow_empty bg-no-repeat bg-center absolute right-[20px] top-1/2 transform translate-y-[-50%]  "></div>
          <div className="pl-[16px] pr-[20px] py-[8px] min-h-[42px] border-x-[1px] border-y-[1px] border-gray ">
            {roles.filter((el) => el.value === roleState)[0]?.description}
          </div>
          {showOptions ? (
            <div className="absolute inset-x-0 top-[100%] bg-white z-[10000] border-x-[1px] border-b-[1px] border-gray ">
              {roles.map((el, index) => (
                <div
                  key={index + 'c02'}
                  className="hover:bg-[#EAF1F7] pl-[16px] pr-[20px] py-[8px]"
                  onClick={() => setRoleState(el.value)}
                >
                  {el.description}
                </div>
              ))}
            </div>
          ) : null}
        </div>

        {list.length ? (
          <>
            <p className="text-gray">Логин</p>
            <ul className="cursor-default flex flex-col gap-y-[5px] max-h-[225px] overflow-y-auto scrollbar">
              {list.map(({ email, id }) => {
                return (
                  email && (
                    <li
                      key={id}
                      className="border-b-[1px] border-gray relative py-2 group flex justify-between last:border-0"
                    >
                      <span>{email}</span>
                      {!isLoading && (
                        <button
                          onClick={() => handleAddUser(id)}
                          className="hidden text-white px-4 py-1 absolute right-0 top-[1px] rounded hover:bg-sky-400 bg-regal_blue group-hover:inline"
                        >
                          Добавить
                        </button>
                      )}
                    </li>
                  )
                );
              })}
              {!isMaxPage && (
                <button
                  className="text-white mb-2 px-4 py-1 right-0 top-[1px] rounded bg-regal_blue disabled:bg-none disabled:bg-[gray]"
                  disabled={isLoading}
                  onClick={nextPage}
                >
                  Показать еще
                </button>
              )}
            </ul>
          </>
        ) : (
          <div className="text-center text-text_light_gray">Нет данных</div>
        )}
      </div>
    </>
  );
}

export default ModalAddUser;
