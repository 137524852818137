import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slice/usersSlice'
import authSlice from './slice/authSlice';
import logsSlice from './slice/logsSlice';

export default configureStore({
  reducer: {
    auth: authSlice,
    users: userSlice,
    logs: logsSlice
  },
  devTools: true,
});
