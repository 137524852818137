import React from 'react';

function UserFilter({ handlerSearch }) {

  const [searchValue, setSearchValue] = React.useState('');

  const handlerChangeSearch = (e) => {
    setSearchValue(e.target.value);
    handlerSearch(e);
  }

  return (
    <div className="flex flex-nowrap justify-between mb-[20px] mt-[25px] w-12/12 ">
      <div className="relative">
        <div className=" absolute left-[16px] top-[9px] w-[18px] h-[18px] bg-search bg-no-repeat bg-left" />
        <input
          className=" py-[8px] pl-[50px] focus:border-blue focus:outline-none rounded-[10px]"
          placeholder="Поиск по списку"
          value={searchValue}
          onChange={handlerChangeSearch}
        />
      </div>
      {/* <div
        className="shadow-[0_4px_15px_rgba(0,0,0,0.05)] rounded-[10px]  relative w-[224px] bg-white pl-[16px] pr-[20px] py-[8px] cursor-pointer "
        onClick={handlerSelect}
      > */}
    </div>
  );
}

export default UserFilter;
// bg-search bg-no-repeat bg-left
