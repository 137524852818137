export const roles = [
  {
    value: 'content-manager',
    description: 'Контент менеджер',
  },
  {
    value: 'administrator',
    description: 'Администратор',
  },
  {
    value: 'organization-user',
    description: 'Пользователь организации',
  },
  {
    value: 'tester',
    description: 'Тестируемый',
  },
  {
    value: 'assessor',
    description: 'Асессор',
  },
];

export const BASE_URL_ADMIN = process.env.REACT_APP_ASSMNT_ADMINKA_API;
