import { useState } from 'react';

export const Popover = ({ text, children }) => {
  const [showPopper, setShowPopper] = useState(false);

  return (
    <span
      className='relative'
      onMouseEnter={() => setShowPopper(true)}
      onMouseLeave={() => setShowPopper(false)}
    >
      {showPopper && (
        <div
          className="clear-both bg-black text-text_light_gray text-popper font-mont_serif font-normal  absolute -top-[80%] shadow-[0_4px_8px_rgba(236,237,240,0.5)] rounded-[2px] px-[12px] py-[4px] z-[2000]">
          {text}
        </div>
      )}
        {children}
    </span>
  );
}

