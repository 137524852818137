import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogsTable from '../../components/logsTable';
import Pagination from '../../components/pagination';
import { getLogsList, showLogsCount, showLogsList } from '../../store/slice/logsSlice';

const initialParams = {
  page: 1,
  limit: 10
};

export const Logs = () => {
  const dispatch = useDispatch();
  const list = useSelector(showLogsList);
  const count = useSelector(showLogsCount);

  const [params, setParams] = useState(initialParams);

  useEffect(() => {
    dispatch(getLogsList(params));
  }, [dispatch, params]);

  const handleChangePagination = (paginationParams) => {
    setParams({ ...params, ...paginationParams });
  };

  return (
    <>
      <div className="bg-bg_gray pt-[50px] px-12">
        <h1 className="text-[#3B4168] font-mont_serif font-semibold not-italic text-h1_main">
          Логи
        </h1>
        <LogsTable
          data={list}
        />
        <Pagination
          {...params}
          totalCount={count}
          paginationChange={handleChangePagination}
        />
      </div>
    </>
  );
};