import React from 'react';

const columns = [
  { title: 'ID', key: 'user_id', classes: 'w-[6%]' },
  { title: 'ФИО', key: 'fio', classes: 'w-[13%]' },
  { title: 'Роль', key: 'role', classes: 'w-[10%]' },
  { title: 'E-mail', key: 'email', classes: 'w-[12%]' },
  { title: 'Дата', key: 'event_date', classes: 'w-[9%]' },
  { title: 'Время', key: 'event_date_time', classes: 'w-[7%]' },
  { title: 'Ошбика\\данные', key: 'error' },
  { title: 'Имя сервиса', key: 'serviceName' },
  { title: 'Сообщения', key: 'message' },
  { title: 'Данные', key: 'data' }
];

function LogsTable({
  data = []
}) {
  const thClasses = 'not-italic font-normal text-tr pb-[16px] pt-[16px]';
  const trClasses = 'pb-[16px] pt-[16px] box-border';

  return (
    <div className="bg-white w-full px-[40px] py-[20px] rounded-[8px] not-italic font-normal text-tr mt-[48px]">

      <table className="table-fixed border-collapse w-full m-[0] text-left">
        <thead>
        <tr className="text-text_light_gray box-border border-b-[1px] border-gray ">
          {columns.map(column => (
            <th
              key={column.key}
              className={`${thClasses} ${column.classes ? column.classes : ''}`}
            >
              {column.title}
            </th>
          ))}
        </tr>
        </thead>
        <tbody className="box-border ">
        {data.map(item => (
          <tr key={item.user_id} className="box-border border-b-[1px] border-gray">
            {columns.map(column => (
              <td
                key={column.key}
                className={trClasses}
              >
                {item[column.key]}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default LogsTable;