import { useEffect, useState } from 'react';
import { roles } from '../../constants/constants';
import { Popover } from '../usersTable/Popover';

function ModalRole({ userData, close, handlerChangeRole }) {
  const [roleState, setRoleState] = useState();
  const [showSelect, setShowSelect] = useState(false);

  const handlerSelect = () => {
    setShowSelect(!showSelect);
  };

  useEffect(() => {
    setRoleState(userData.role);
  }, [userData.role]);
  return (
    <>
      <div
        className="fixed z-[10] inset-0 bg-opacity-50 bg-black cursor-pointer"
        onClick={close}
      />
      <div className="z-[11] w-[432px] fixed left-2/4 top-2/4 transform translate-y-[-50%] translate-x-[-50%] bg-white rounded-[8px] border-t-[4px] border-[orange] p-[40px]">
        <div className="flex flex-row justify-between">
          <div className="bg-pencil w-[28px] h-[28px]"></div>
          <div
            className="bg-close w-[28px] h-[28px] cursor-pointer"
            onClick={close}
          ></div>
        </div>
        <span className="text-text_light_gray not-italic font-normal text-tr  mb-[4px]">
          E-mail пользователя
        </span>
        <h2 className="text-[#1E253B] font-mont_serif font-semibold not-italic text-h1_main mt-[28px] mb-[28px] text-ellipsis overflow-x-clip">
          <Popover text={userData.oneid.email}>
            <span>{userData.oneid.email}</span>
          </Popover>
        </h2>
        <span className="text-text_light_gray not-italic font-normal text-tr  mb-[4px]">
          Роль пользователя
        </span>
        <div
          onClick={handlerSelect}
          className="cursor-pointer relative w-full z-10"
        >
          <div className="w-[12px] h-[12px] bg-arrow_empty bg-no-repeat bg-center absolute right-[20px] top-1/2 transform translate-y-[-50%]  "></div>
          <div className="pl-[16px] pr-[20px] py-[8px] min-h-[42px] border-x-[1px] border-y-[1px] border-gray ">
            {roles.filter((el) => el.value === roleState)[0]?.description}
          </div>
          {showSelect ? (
            <div className="absolute inset-x-0 top-[100%] bg-white z-[10000] border-x-[1px] border-b-[1px] border-gray ">
              {roles.map((el, index) => (
                <div
                  key={index + 'c02'}
                  className="hover:bg-[#EAF1F7] pl-[16px] pr-[20px] py-[8px]"
                  onClick={() => setRoleState(el.value)}
                >
                  {el.description}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <button
          className="bg-regal_blue text-white p-[8px] mt-[40px] rounded-[4px] w-full"
          onClick={() => {
            handlerChangeRole(userData.id_auth, roleState);
            close();
          }}
        >
          Сохранить изменения
        </button>
      </div>
    </>
  );
}

export default ModalRole;
