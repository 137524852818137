import { useState } from 'react';
import { roles } from '../../constants/constants';
import ModalDeleteUser from '../../components/modals/modalDeleteUser';

const getLocaleDateString = (date) => {
  return date ? new Date(date).toLocaleDateString() : '...'
}

function TableRow({ user, handlerShowModalRole, index, handlerDeleteUser }) {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const FIO = `${user.oneid.sur_name} ${user.oneid.name} ${user.oneid.patronymic}`
  return (
    <>
    <tr key={index} className="box-border border-b-[1px] border-gray group">
      <td className="pb-[16px] pt-[16px] box-border">{user.id_auth}</td>
      <td className="pb-[16px] pt-[16px] box-border">{FIO}</td>
      <td
        className={
          user.role === 'administrator'
            ? 'text-text_red pb-[16px] pt-[16px] box-border'
            : 'text-text_light_blue pb-[16px] pt-[16px] box-border'
        }
      >
        {roles.filter((role) => role.value === user.role)[0]?.description || "..."}
        {/* {user.role === 'administrator'
          ? 'Администратор'
          : user.role === 'content-manager'
          ? 'Контент менеджер'
          : user.role === 'organization-user'
          ? 'пользователь организации'
          : user.role === 'tester'
          ? 'тестируемый'
          : 'other'} */}
      </td>
      <td className="pb-[16px] pt-[16px] box-border">{user.oneid.email || "..."}</td>
      <td className="pb-[16px] pt-[16px] box-border">
        {getLocaleDateString(user.created_at)}
      </td>
      <td className="pb-[16px] pt-[16px] box-border">
        {getLocaleDateString(user.updated_at)}
      </td>
      {user.role && <td className='flex justify-end w-[50px]'>
        <div
          className={` relative opacity-0 bg-pencil_v2 bg-no-repeat bg-center pb-[16px] w-[28px] h-[w8px] cursor-pointer pt-[16px] group-hover:opacity-100`}
          onClick={() => handlerShowModalRole(index)}
        />
        <div
          className={` relative opacity-0 bg-trash_icon bg-no-repeat bg-center pb-[16px] w-[28px] h-[w8px] cursor-pointer pt-[16px] m-[8px] group-hover:opacity-100`}
          onClick={() => setShowModalDelete(true)}
        />
      </td>}
    </tr>
      {showModalDelete &&
        <ModalDeleteUser
          close={() => setShowModalDelete(false)}
          userData={user}
          handlerDeleteUser={handlerDeleteUser}
        />}
    </>
  );
}
export default TableRow;
