import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_ADMIN } from '../../constants/constants';

const initialState = {
  token: '',
  loading: true,
};

export const auth = createAsyncThunk(
  'users/auth',
  async (token, { rejectWithValue }) => {
    try {
      await axios.get(BASE_URL_ADMIN + '/api/v1/login', {
        headers: { Authorization: token }
      });
      return token;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  extraReducers: {
    [auth.fulfilled]: (state, action) => {
      state.token = action.payload;
      state.loading = false;
    },
    [auth.rejected]: (state) => {
      state.loading = false;
    },
  }
});

export const showAuth = (state) => ({isAuth: Boolean(state.auth.token), isLoading: state.auth.loading});

export default authSlice.reducer;
