import { useMemo } from 'react';
import cn from 'classnames';
import { Select } from '../ui/Select/Select';

const OPTIONS = [{label: 10, value: 10}, {label: 15, value: 15}, {label: 20, value: 20}];
function Pagination({
  page,
  limit,
  totalCount,
  paginationChange,
  options = OPTIONS
}) {
  const numberOfPages = Math.ceil(totalCount / limit);

  const changeStep = (limit) => {
    paginationChange({ limit, page: 1 });
  };

  const changePage = (page) => {
    paginationChange({ page, limit });
  };

  const whatPageText = useMemo(() => {
    let startWithNumberOfUser = totalCount > 0 ? (page - 1) * limit + 1 : 0;
    let endWithNumberOfUser = (page + 1) * limit > totalCount ? totalCount : page * limit;
    return ` ${startWithNumberOfUser}-${endWithNumberOfUser} из ${totalCount} `
  }, [totalCount, limit, page])

  const isTopOfPage = page === 1;
  const isEndOfPage = page === numberOfPages;

  const prevButtonClasses =  cn(`px-2 -tracking-[5px]`, isTopOfPage ? 'text-gray' : 'text-blue');
  const nextButtonClasses =  cn(`px-2 -tracking-[5px]`, isEndOfPage ? 'text-gray' : 'text-blue');

  if (totalCount <= 0) return null;

  return (
    <div className="flex justify-center my-20px">
      <div className="flex bg-white justify-between px-5 w-full items-center drop-shadow-[0_4px_8px_rgba(236,237,240,0.5)]  rounded-[8px] ">
        <span className="text-slate-400 ">Всего пользователей: {totalCount}</span>
        <div className="flex gap-3 py-2 items-center">
          <Select
            value={limit}
            options={options}
            onChange={changeStep}
          />
          <span className="text-popper text-slate-500 cursor-default">
            {whatPageText}
          </span>
          <button
              disabled={isTopOfPage}
              className={prevButtonClasses}
              onClick={() => changePage(1)}
          >
            &lt;&lt;
          </button>
          <button
            disabled={isTopOfPage}
            className={prevButtonClasses}
            onClick={() => changePage(page - 1)}
          >
            &lt;
          </button>
          <button
            disabled={isEndOfPage}
            className={nextButtonClasses}
            onClick={() => changePage(page + 1)}
          >
            &gt;
          </button>
          <button
              disabled={isEndOfPage}
              className={nextButtonClasses}
              onClick={() => changePage(numberOfPages)}
          >
            &gt;&gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
