import toast from 'react-hot-toast';

const classNames = {
  success: 'bg-success',
  error: 'bg-error'
}

export const customToast = ({text, type}) => {
  toast.custom((t) => (
      <div className={`min-w-[350px] ${t.visible ? 'animate-enter' : 'animate-leave'}`}>
        <div className={`${classNames[type]} h-[10px] rounded-t-[4px] -mb-[7px] relative`}></div>
        <div className='bg-white p-4 rounded-[2px] flex justify-between gap-x-5 z-10 relative'>
          <span className='font-medium'>{text}</span>
          <div
              className="bg-close bg-cover w-[17px] h-[17px] cursor-pointer"
              onClick={() => toast.dismiss(t.id)}
          />
        </div>
        <div className={`h-[10px] ${classNames[type]} rounded-b-[4px] animate-progress -mt-[7px] relative`}></div>
      </div>
  ))
}
