import axios from 'axios';
import { BASE_URL_ADMIN } from '../constants/constants';

const axiosDefault = axios.create({
  baseURL: BASE_URL_ADMIN
});

let store;

export const injectStore = _store => {
  store = _store
}

axiosDefault.interceptors.request.use(config => {
  config.headers.authorization = store.getState().auth.token
  return config
})

export default axiosDefault;