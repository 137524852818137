import TableRow from './tableRow';

function UsersTable({
  userList,
  handlerShowModalRole,
  handlerShowModalAddUser,
  handlerDeleteUser,
}) {

  return (
    <div className="bg-white w-full px-[40px] py-[20px] rounded-[8px] not-italic font-normal text-tr">
      <div className="flex flex-nowrap justify-between mb-[40px]">
        <button className="bg-button_light_blue px-[16px] py-[4px] rounded-[4px] text-white not-italic font-normal text-tr ">
          Пользователи
        </button>
        <div>
          <a
            href={process.env.REACT_APP_ASSMNT_ASSESSOR_WEB}
            target="_blank"
            className="text-text_user_add not-italic font-normal text-but_add_user cursor-pointer mr-5"
          >
            Интерфейс асессора
          </a>
          <span
            className="text-text_user_add not-italic font-normal text-but_add_user cursor-pointer"
            onClick={handlerShowModalAddUser}
          >
          + Добавить пользователя
        </span>
        </div>
      </div>

      <table className="table-fixed border-collapse w-full m-[0] text-left">
        <thead>
          <tr className="text-text_light_gray  ">
            <th className="w-[20%] not-italic font-normal text-tr">Id auth</th>
            <th className="w-[20%] not-italic font-normal text-tr">ФИО</th>
            <th className="w-[15%] not-italic font-normal text-tr">Роль</th>
            <th className="w-[25%] not-italic font-normal text-tr">Почта</th>
            <th className="w-[15%] not-italic font-normal text-tr">Дата создания</th>
            <th className="w-[10%] not-italic font-normal text-tr">Дата изменения</th>
            <th className="w-[40px] not-italic font-normal text-tr"></th>
          </tr>
        </thead>
        <tbody className="box-border w-[100%]">
          {!!userList?.length &&
            userList.map((user, index) => (
            <TableRow
              key={index + 'a11'}
              user={user}
              handlerShowModalRole={handlerShowModalRole}
              index={index}
              handlerDeleteUser={handlerDeleteUser}
            />
          ))}
        </tbody>
      </table>
      { !userList?.length &&
        <div className="text-center text-text_light_gray my-5">
          Нет данных
        </div>
      }
    </div>
  );
}

export default UsersTable;
