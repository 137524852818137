import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import App from './container/App';
import store from './store';
import './index.css';
import { injectStore } from './utils/api';
injectStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Wrapper>
      <Provider store={store}>
        <App />
      </Provider>
    </Wrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
