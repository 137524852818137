import React from 'react'
import { useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export const Select = ({
  options,
  value,
  onChange,
  placeholder,
  wrapperClassName = ''
}) => {
  const [selected, setSelected] = React.useState(placeholder || value || options[0].label);
  const [isOpen, setIsOpen] = React.useState(false);
  const [innerBlockUp, setInnerBlockUp] = React.useState(false);
  const innerRef = useRef();
  const outerRef = useRef();

  useOutsideClick(outerRef, () => setIsOpen(false))

  React.useEffect(() => {
    setSelected(value || placeholder || options[0].label);
  }, [value]);

  React.useEffect(() => {
    if (!isOpen) {
      return
    }
    const Outer = outerRef.current.getBoundingClientRect();
    const Inner = innerRef.current.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    screenHeight > Outer.bottom + Inner.height + 10
      ? setInnerBlockUp(false)
      : setInnerBlockUp(true);
  }, [isOpen])

  const handleSelect = ({ label, value }) => {
    setSelected(label);
    onChange && onChange(value);
  }

  return (
    <div
      ref={outerRef}
      className={`${wrapperClassName} shadow-[0_4px_15px_rgba(0,0,0,0.05)]
      rounded-[10px] relative max-w-[224px] bg-white pl-2 pr-7 py-2 cursor-pointer`}
      onClick={() => setIsOpen(isOpen => !isOpen)}
    >
      <div className='pr-3'>{selected}</div>
      <div
        className={`z-2 w-2 h-2 bg-arrow bg-no-repeat bg-center absolute 
      right-[20px] top-1/2 transform translate-y-[-50%]`}
      />

      <div
        ref={innerRef}
        className={`
        absolute w-full left-0 ${innerBlockUp ? 'bottom-[calc(100%+10px)]'
            : 'top-[calc(100%+10px)]'} shadow-[0_4px_15px_rgba(0,0,0,0.05)] 
        rounded-[10px] max-w-[224px] bg-white cursor-pointer ${isOpen ? 'block' : 'hidden'}`}
      >
        {options.map((option, index) => (
            <div
                key={index}
                className={`hover:text-blue hover:bg-[#EAF1F7] px-2 py-1
                ${selected === option.label ? 'text-sky-400' : ''}`}
                onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
        ))}
      </div>
    </div>
  )
}
